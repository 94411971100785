import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';

import Confetti from './Confetti';
import styles from './ConfettiStyles';

const useStyles = makeStyles({ name: 'ConfettiCanvas' })(styles);

function ConfettiCanvas(props) {
  const { palette } = useTheme();

  const {
    maxParticles = 150,
    colors = palette.confettiColors,
    party = false,
    onPartyIsOver,
  } = props;
  const { classes } = useStyles();

  // Refs
  const canvasRef = useRef(null);

  // State
  const [animationComplete, setAnimationComplete] = useState(true);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [confettiObj, setConfettiObj] = useState(null);

  // Dimensions
  const { width, height } = dimensions;

  /**
   * Set canvas width and height on resize
   */
  const onResize = () => {
    const { innerWidth, innerHeight } = window;

    setDimensions({ width: innerWidth, height: innerHeight });
  };

  const onAnimationComplete = useCallback(() => {
    setAnimationComplete(true);
    setConfettiObj(null);
    onPartyIsOver();
  }, [onPartyIsOver]);

  /**
   * Resets the confetti object
   */
  const resetConfetti = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const props = { context, width, height, maxParticles, colors, onAnimationComplete };
    const confettiObj = Confetti(props);

    setConfettiObj(confettiObj);
  }, [colors, height, maxParticles, width, onAnimationComplete]);

  /**
   * Set canvas initial dimensions
   */
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  /**
   * Use flags to turn animation on and off.
   */
  useEffect(() => {
    if (party && animationComplete) {
      setAnimationComplete(false);
    }

    if (!party && !animationComplete && confettiObj) {
      confettiObj.deactivateConfetti();
    }

    if (party && !animationComplete) {
      if (!confettiObj) resetConfetti();
      if (confettiObj) confettiObj.restartConfetti();
    }
  }, [party, animationComplete, confettiObj, resetConfetti, canvasRef]);

  /**
   * Reset confetti function if props have changed
   */
  useEffect(() => {
    if (canvasRef.current) {
      setConfettiObj(null);
      resetConfetti();
    }
  }, [colors, height, maxParticles, width, resetConfetti]);

  if (animationComplete) return null;

  return (
    <canvas
      width={width}
      height={height}
      ref={canvasRef}
      className={classes.root}
      data-cy="confetti"
    />
  );
}

ConfettiCanvas.propTypes = {
  maxParticles: PropTypes.number,
  colors: PropTypes.array,
  party: PropTypes.bool,
  onPartyIsOver: PropTypes.func,
};

export default ConfettiCanvas;
